import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getEvent } from "../redux/modules/events";
import Event from "../models/Event";
import { GET_EVENT } from "../redux/actions";

/**
 * A container that fetches and provides data about a single event
 */
export class EventContainer extends React.Component {
  static propTypes = {
    /** A function called with data about the event */
    children: PropTypes.func.isRequired,
    /** A function to call to fetch the event from the API. Provided by Redux */
    getEvent: PropTypes.func.isRequired,
    /** The relevant event. Provided by Redux. */
    event: PropTypes.instanceOf(Event),
    /** The ID of the event to display */
    eventId: PropTypes.string.isRequired
  };

  state = {
    isLoading: false,
    errorCode: null,
    errorMessage: null
  };

  componentDidMount() {
    // Fetch the event if we don't have it when mounting
    if (!this.props.event) {
      this.fetchEvent();
    }
  }

  componentDidUpdate(prevProps) {
    // If we change the event id and don't have that event, fetch it
    if (prevProps.eventId !== this.props.eventId && !this.props.event) {
      this.fetchEvent();
    }
  }

  /**
   * Fetches the event and updates state's isLoading, errorCode, and errorMessage.
   */
  fetchEvent = async () => {
    this.setState({
      isLoading: true,
      errorCode: null,
      errorMessage: null
    });
    await this.props.getEvent(this.props.eventId).catch(e => {
      this.setState({
        errorCode: e.status,
        errorMessage: e.data.error
      });
    });
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      event: this.props.event,
      errorCode: this.state.errorCode,
      errorMessage: this.state.errorMessage,
      isLoading: this.state.isLoading,
      refresh: this.fetchEvent
    });
  }
}

const mapStateToProps = (state, props) => ({
  event: getEvent(state, props.eventId)
});

const mapDispatchToProps = {
  getEvent: GET_EVENT
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventContainer);
