import showError from "../../utils/showError";
import Event from "../../models/Event";

/**
 * Fetches an event by ID
 * @param  {String} eventId The ID of the event to fetch
 * @return {Promise<Event>}
 */
export default async function getEvent(eventId) {
  const response = await this.axiosInstance
    .get(`/events/${eventId}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  const {
    id,
    name,
    description,
    startDate,
    endDate,
    city,
    state,
    region
  } = response.data;
  return new Event({
    id: id.toString(),
    name,
    description,
    startDate,
    endDate,
    city,
    state,
    region
  });
}
