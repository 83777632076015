import axios from "axios";
import checkLoginStatus from "./competition/checkLoginStatus";
import getEvent from "./competition/getEvent";
import getEvents from "./competition/getEvents";
import logIn from "./competition/logIn";
import logOut from "./competition/logOut";

export class CompetitionApi {
  constructor() {
    this.config = {
      baseURL: this.baseUrl,
      headers: {
        Accept: "application/json"
      }
    };

    this.axiosInstance = axios.create(this.config);
  }

  /**
   * The base url for all API requests
   * @type {String}
   */
  baseUrl = process.env.REACT_APP_API_BASE;

  /**
   * The axios configuration.
   * @type {Object}
   */
  config = {};

  /**
   * The axios instance.
   * @type {Axios}
   */
  axiosInstance = null;

  /**
   * Check if the user is already logged in
   * @return {Promise}
   */
  checkLoginStatus() {
    return checkLoginStatus.call(this);
  }

  /**
   * Get an event based on the provided eventId
   * @param  {String} eventId
   * @return {Promise<Event>}
   */
  getEvent(eventId) {
    return getEvent.call(this, eventId);
  }

  /**
   * Get events based on the provided EventFindRequest
   * @param  {EventFindRequest} eventFindRequest
   * @return {Promise<Object>} resolves to { events: Event[], meta: object }
   */
  getEvents(eventFindRequest) {
    return getEvents.call(this, eventFindRequest);
  }

  /**
   * Log in using email and password.
   * @param {String} email
   * @param {String} password
   * @return {Promise}
   */
  logIn(email, password) {
    return logIn.call(this, email, password);
  }

  /**
   * Log out.
   * @return {Promise}
   */
  logOut() {
    return logOut.call(this);
  }
}

export default new CompetitionApi();
