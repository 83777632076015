import { handleActions } from "redux-actions";
import { fromJS } from "immutable";
import { CHECK_LOGIN, LOG_IN, LOG_OUT } from "../actions";

export const defaultState = fromJS({
  isCheckingLogin: true,
  isLoggedIn: false,
  isLoggingIn: false,
  user: null
});

/**
 * The reducer managing data about the user.
 */
export default handleActions(
  {
    [CHECK_LOGIN.REQUESTED]: state => {
      return state.set("isCheckingLogin", true);
    },
    [CHECK_LOGIN.RECEIVED]: (state, action) => {
      const user = action.payload;
      return state.merge({
        isLoggedIn: !!user,
        isCheckingLogin: false,
        user
      });
    },
    [CHECK_LOGIN.FAILED]: state => {
      return state.merge({
        isLoggedIn: false,
        isCheckingLogin: false,
        user: null
      });
    },
    [LOG_IN.REQUESTED]: state => {
      return state.set("isLoggingIn", true);
    },
    [LOG_IN.RECEIVED]: (state, action) => {
      const user = action.payload;
      return state.merge({
        isLoggedIn: !!user,
        isLoggingIn: false,
        user
      });
    },
    [LOG_IN.FAILED]: state => {
      return state.merge({
        isLoggedIn: false,
        isLoggingIn: false,
        user: null
      });
    },
    [LOG_OUT.REQUESTED]: state => {
      return state.merge({
        isLoggedIn: false,
        user: null
      });
    }
  },
  defaultState
);

/**
 * Indicates if we are currently checking the user's login.
 * @param  {Map} state
 * @return {Boolean}
 */
export const getCheckingLogin = state =>
  state.getIn(["user", "isCheckingLogin"]);

/**
 * Indicates if the user is currently logged in.
 * @param  {Map} state
 * @return {Boolean}
 */
export const getIsLoggedIn = state => state.getIn(["user", "isLoggedIn"]);

/**
 * Details about the currently logged in user
 * @param  {Map} state
 * @return {User}
 */
export const getUser = state => state.getIn(["user", "user"]);
