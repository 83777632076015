import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BlockListItem, H2, P, Spacer } from "react-watty-ui";
import styled from "styled-components";
import Event from "../../models/Event";

export const StyledWrapper = styled(BlockListItem)`
  &:hover {
    background: ${props => props.theme.colors.silver};
  }
`;

/**
 * A block of content displaying information about an Event.
 *
 * Any undocumented props will be spread onto the top-level DOM element.
 */
const EventBlock = ({ event, ...props }) => (
  <StyledWrapper {...props} as={Link} to={`/events/${event.get("id")}`}>
    <H2>{event.get("name")}</H2>
    <Spacer as="div" top="sm" />
    <P>
      <span data-test-id="start-date">
        {event.get("startDateMoment").format("MMM D, YYYY")}
      </span>{" "}
      -{" "}
      <span data-test-id="end-date">
        {event.get("endDateMoment").format("MMM D, YYYY")}
      </span>{" "}
      | {event.get("city")}, {event.get("stateShort")} | Region{" "}
      {event.get("region")}
    </P>
  </StyledWrapper>
);

EventBlock.propTypes = {
  /** The Event to display information about */
  event: PropTypes.instanceOf(Event)
};

export default EventBlock;
