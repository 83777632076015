import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import moment from "moment";
import { STATES } from "../constants/geography";

/**
 * An event where competitions took place.
 */
const Event = createModel("Event", {
  id: null,
  name: null,
  description: null,
  startDate: null,
  startDateMoment: computed(function() {
    return moment(this.get("startDate"), "YYYY-MM-DD");
  }),
  endDate: null,
  endDateMoment: computed(function() {
    return moment(this.get("endDate"), "YYYY-MM-DD");
  }),
  city: null,
  state: null,
  stateShort: computed(function() {
    const state = this.get("state");
    const stateObject = STATES.find(_state => _state.full === state);
    if (!stateObject) {
      return state;
    }
    return stateObject.short;
  }),
  region: null
});

Event.propTypes = {
  /** The ID of the event */
  id: PropTypes.string.isRequired,
  /** A readable name representing the event */
  name: PropTypes.string,
  /** A short description of the event */
  description: PropTypes.string,
  /** The date when the event started */
  startDate: PropTypes.string.isRequired,
  /** The start date parsed by moment.js */
  startDateMoment: PropTypes.object,
  /** The date when the event ended */
  endDate: PropTypes.string.isRequired,
  /** The end date parsed by moment.js */
  endDateMoment: PropTypes.object,
  /** The city in which the event took place */
  city: PropTypes.string,
  /** The state in which the event took place */
  state: PropTypes.string,
  /** An abbrevated version of the state */
  stateShort: PropTypes.string,
  /** The AKA region in which the event took place */
  region: PropTypes.string
};

export default Event;

export function getMockEvent(overrides = {}) {
  return new Event({
    id: 123,
    name: "WSIKF",
    description: "Washington State International Kite Festival",
    startDate: "2019-8-20",
    endDate: "2019-8-27",
    city: "Long Beach",
    state: "Washington",
    region: "10",
    ...overrides
  });
}
