import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { GET_EVENT, GET_EVENTS } from "../actions";

export const defaultState = fromJS({
  events: {},
  totalCount: null
});

/**
 * The reducer managing data about Events
 * Maintains an id-indexed map of events as well as some meta data.
 */
export default handleActions(
  {
    [GET_EVENT.RECEIVED]: (state, action) => {
      return state.setIn(["events", action.payload.get("id")], action.payload);
    },
    // When we retrieve events, overwrite the events and totalCount based on the response
    [GET_EVENTS.RECEIVED]: (state, action) => {
      return state.merge({
        events: fromJS(
          action.payload.events.reduce((accumulated, event) => {
            accumulated[event.get("id")] = event;
            return accumulated;
          }, {})
        ),
        totalCount: action.payload.meta.totalCount
      });
    }
  },
  defaultState
);

/**
 * Returns the events in an order based on the provided find request.
 * @param  {Immutable.Map} state
 * @param  {EventFindRequest} findRequest
 * @return {Event[]}
 */
export const getEvents = (state, findRequest) => {
  const eventsMap = state.getIn(["events", "events"]);
  const eventsArray = eventsMap.valueSeq().toJS();

  // When a find request is used to get events, the server responds with those
  // events in the order requested. However, we store those events in a map, which
  // has no inherent order. Reinforcing the order here.
  // Maybe there's a better way to do this with more robust cacheing? Need to
  // think about it.
  if (findRequest) {
    const orderBy = findRequest.get("orderBy");
    const ascending = findRequest.get("orderDirection") === "ascending";
    eventsArray.sort((event1, event2) => {
      const event1Metric = event1.get(orderBy);
      const event2Metric = event2.get(orderBy);
      if (event1Metric > event2Metric) {
        return ascending ? 1 : -1;
      }
      if (event1Metric < event2Metric) {
        return ascending ? -1 : 1;
      }
      return 0;
    });
  }
  return eventsArray;
};

/**
 * Returns the event based on event id.
 * @param  {Immutable.Map} state
 * @param  {String} eventId
 * @return {Event}
 */
export const getEvent = (state, eventId) => {
  return state.getIn(["events", "events", eventId]);
};
