// import { createAction } from "redux-actions";
import CompetitionApi from "../api/CompetitionApi";
import createAsyncAction from "./createAsyncAction";

/**
 * Check if the user is already logged in.
 */
export const CHECK_LOGIN = createAsyncAction("CHECK_LOGIN", () => {
  return CompetitionApi.checkLoginStatus();
});

/**
 * Get an event based on the eventId
 */
export const GET_EVENT = createAsyncAction("GET_EVENT", eventId => {
  return CompetitionApi.getEvent(eventId);
});

/**
 * Get events based on the eventFindRequest.
 */
export const GET_EVENTS = createAsyncAction("GET_EVENTS", eventFindRequest => {
  return CompetitionApi.getEvents(eventFindRequest);
});

/**
 * Log in using email and password
 */
export const LOG_IN = createAsyncAction("LOG_IN", (email, password) => {
  return CompetitionApi.logIn(email, password);
});

/**
 * Log out.
 */
export const LOG_OUT = createAsyncAction("LOG_OUT", () => {
  return CompetitionApi.logOut();
});
