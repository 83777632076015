import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import { STATES } from "../../constants/geography";

const EventFindRequest = createModel("EventFindRequest", {
  nameContains: null,
  stateList: [],
  stateNotList: [],
  startDateGreaterThan: null,
  startDateLessThan: null,
  endDateGreaterThan: null,
  endDateLessThan: null,
  limit: 100,
  skip: 0,
  orderBy: "id",
  orderDirection: "ascending",
  payload: computed(function() {
    const payload = this.getProperties(
      "nameContains",
      "stateList",
      "stateNotList",
      "startDateGreaterThan",
      "startDateLessThan",
      "endDateGreaterThan",
      "endDateLessThan",
      "limit",
      "skip",
      "orderBy",
      "orderDirection"
    );
    Object.keys(payload).forEach(key => {
      const value = payload[key];
      if (Array.isArray(value)) {
        payload[key] = value.join(",");
      }
      if (payload[key] === null || payload[key] === "") {
        delete payload[key];
        return;
      }
    });
    return payload;
  })
});

EventFindRequest.propTypes = {
  // A string to search for based on name
  nameContains: PropTypes.string,
  // A list of states to include events for
  stateList: PropTypes.arrayOf(
    PropTypes.oneOf(STATES.map(state => state.full))
  ),
  // A list of states to exclude events for
  stateNotList: PropTypes.arrayOf(
    PropTypes.oneOf(STATES.map(state => state.full))
  ),
  // Include events that started after this date
  startDateGreaterThan: PropTypes.string,
  // Include events that started before this date
  startDateLessThan: PropTypes.string,
  // Include events that ended after this date
  endDateGreaterThan: PropTypes.string,
  // Include events that ended before this date
  endDateLessThan: PropTypes.string,
  // The maximum number of records to return in one request
  limit: PropTypes.number,
  // The number of records to skip in the request; for pagination
  skip: PropTypes.number,
  // The property to sort the events by
  orderBy: PropTypes.oneOf(["id", "name"]),
  // The direction in which to sort the events
  orderDirection: PropTypes.oneOf(["ascending", "descending"]),
  // The payload to be used to communicate with the server
  payload: PropTypes.shape({
    // stateList and stateNotList should be converted to comma separated values
    stateList: PropTypes.string,
    stateNotList: PropTypes.string
  })
};

export default EventFindRequest;
