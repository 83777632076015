import styled from "styled-components";

/**
 * Creates a wrapper that constrains the content to a maximum width.
 */
const PageWrapper = styled.div`
  min-height: calc(100vh - ${({ theme }) => theme.topNavHeight}px);
  max-width: ${({ theme }) => theme.applicationWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 16px 24px;
`;

export default PageWrapper;
