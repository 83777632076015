import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Dropdown, TextButton } from "react-watty-ui";
import User from "../../models/User";

export const Avatar = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.silver};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
`;

/**
 * The navigation item containing the user's account information.
 */
const AccountNav = ({ user, onLogOut, ...props }) => (
  <Dropdown
    dropdownContent={dropdown => (
      <dropdown.components.Item onClick={onLogOut} data-test-id="log-out">
        Log Out
      </dropdown.components.Item>
    )}
  >
    {dropdownData => (
      <TextButton
        {...props}
        onClick={dropdownData.actions.open}
        data-test-id="open-account-dropdown"
      >
        <Avatar>
          <Icon icon="user" />
        </Avatar>
        {user.get("firstName")} <Icon icon="angle-down" />
      </TextButton>
    )}
  </Dropdown>
);

AccountNav.propTypes = {
  /** Details about the current user */
  user: PropTypes.instanceOf(User).isRequired,

  /** Called when the user wants to log out */
  onLogOut: PropTypes.func.isRequired
};

export default AccountNav;
