import React from "react";
import Router from "./Router";
import TopNav from "./topNav/TopNav";

const App = () => (
  <React.Fragment>
    <TopNav />
    <Router />
  </React.Fragment>
);

export default App;
