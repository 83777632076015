import React from "react";
import { H1, Spacer, Loading } from "react-watty-ui";
import EventsContainer from "../../containers/EventsContainer";
import PageWrapper from "../PageWrapper";
import EventBlock from "./EventBlock";
import EventsEmpty from "./EventsEmpty";

/**
 * The coordinating component for the Events page.
 */
const Events = () => (
  <PageWrapper>
    <EventsContainer>
      {({ events, isLoading }) => (
        <React.Fragment>
          <H1>Events</H1>
          <Spacer as="div" bottom="md" />
          {isLoading && <Loading />}
          {!isLoading && !events.length ? (
            <EventsEmpty />
          ) : (
            events.map(event => (
              <EventBlock event={event} key={event.get("id")} />
            ))
          )}
        </React.Fragment>
      )}
    </EventsContainer>
  </PageWrapper>
);

export default Events;
