import React from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Home from "./home/Home";
import Events from "./events/Events";
import Event from "./event/Event";
import Login from "./login/Login";

const Router = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/events" component={Events} />
    <Route exact path="/login" component={Login} />
    <Route path="/events/:eventId" component={Event} />
    <Route component={ErrorPage} />
  </Switch>
);

export default Router;
