import User from "../../models/User";

/**
 * Check if the user is already logged in.
 * @return {Promise}
 */
export default async function checkLoginStatus() {
  // Make the request
  const response = await this.axiosInstance.get("/auth");

  // Build and return the user
  const data = response.data;
  const user = new User({
    firstName: data.firstName,
    lastName: data.lastName,
    memberId: data.memberId,
    region: data.region,
    role: data.role
  });

  // Return the response
  return user;
}
