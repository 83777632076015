import React from "react";
import PropTypes from "prop-types";
import { Loading, H1, P, Spacer } from "react-watty-ui";
import EventContainer from "../../containers/EventContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";

/**
 * Top level layout component for the Event page.
 */
const Event = ({ match }) => {
  const eventId = match.params.eventId;
  return (
    <EventContainer eventId={eventId}>
      {({ isLoading, event, errorCode, errorMessage }) => {
        if (isLoading) {
          return (
            <PageWrapper>
              <Loading />
            </PageWrapper>
          );
        }
        if (!event) {
          return (
            <ErrorPage
              errorCode={errorCode || 404}
              errorMessage={errorMessage || "Event not found"}
            />
          );
        }
        return (
          <PageWrapper data-test-id="event-content">
            <H1>{event.get("name")}</H1>
            <Spacer bottom="sm" as="div" />
            <P>
              <span data-test-id="start-date">
                {event.get("startDateMoment").format("MMM D, YYYY")}
              </span>{" "}
              -{" "}
              <span data-test-id="end-date">
                {event.get("endDateMoment").format("MMM D, YYYY")}
              </span>{" "}
              | {event.get("city")}, {event.get("stateShort")} | Region{" "}
              {event.get("region")}
            </P>
            <Spacer bottom="md" as="div" />
            <P>Description: {event.get("description")}</P>
          </PageWrapper>
        );
      }}
    </EventContainer>
  );
};

Event.propTypes = {
  /** The route match data provided by Link */
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Event;
