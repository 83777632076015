import { combineReducers } from "redux-immutable";
import UserReducer from "./user";
import EventsReducer from "./events";

/**
 * The global combined reducer
 */
export default combineReducers({
  user: UserReducer,
  events: EventsReducer
});
