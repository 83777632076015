import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import User from "../models/User";
import { getIsLoggedIn, getUser } from "../redux/modules/user";
import { LOG_OUT } from "../redux/actions";

/**
 * A generic container that provides access to user data.
 */
export class UserContainer extends React.Component {
  static propTypes = {
    /** A function to provide data to */
    children: PropTypes.func.isRequired,
    /** Is the user currently logged in? */
    isLoggedIn: PropTypes.bool.isRequired,
    /** A function to call to log out the user. Provided by Redux */
    logOut: PropTypes.func.isRequired,
    /** Details about the current user */
    user: PropTypes.instanceOf(User)
  };

  static defaultProps = {
    user: null
  };

  render() {
    return this.props.children({
      isLoggedIn: this.props.isLoggedIn,
      user: this.props.user,
      logOut: this.props.logOut
    });
  }
}

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
  user: getUser(state)
});

const mapDispatchToProps = {
  logOut: LOG_OUT
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContainer);
