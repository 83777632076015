import React from "react";
import { P } from "react-watty-ui";

/**
 * A simple empty state for the Events page.
 */
const EventsEmpty = () => (
  <P>No events could be found. Try adjusting your filters.</P>
);

export default EventsEmpty;
