import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { A, H2, H3, Spacer } from "react-watty-ui";
import UserContainer from "../../containers/UserContainer";
import LogInLink from "./LogInLink";
import AccountNav from "./AccountNav";

export const StyledTopNav = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.silver};
  height: ${({ theme }) => theme.topNavHeight}px;
`;

export const TopNavWrap = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.applicationWidth}px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/akaSportLogo.png"});
  height: 48px;
  width: 48px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const RightContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const VisibleOnTabletOrLarger = styled.div`
  display: none;
  ${({ theme }) => theme.media.mediaSm} {
    display: block;
  }
`;

const TopNav = () => (
  <StyledTopNav>
    <TopNavWrap>
      <Spacer right="sm" as={Link} to="/">
        <Logo />
      </Spacer>
      <VisibleOnTabletOrLarger as={Link} to="/">
        <H2>AKA Sports</H2>
        <H3>American Kitefliers Association</H3>
      </VisibleOnTabletOrLarger>
      <RightContent>
        <A as={Link} to="/events">
          Events
        </A>
        <Spacer right="md" />
        <UserContainer>
          {userContainer => {
            if (!userContainer.isLoggedIn) {
              return <LogInLink />;
            }
            return (
              <AccountNav
                user={userContainer.user}
                onLogOut={userContainer.logOut}
              />
            );
          }}
        </UserContainer>
      </RightContent>
    </TopNavWrap>
  </StyledTopNav>
);

export default TopNav;
