import React from "react";
import { A } from "react-watty-ui";
import { Link } from "react-router-dom";

/**
 * A link to the log in page
 */
const LogInLink = props => (
  <A as={Link} to="/login" {...props}>
    Log In
  </A>
);

export default LogInLink;
