import PropTypes from "prop-types";
import { createModel } from "manikin-model";

/**
 * A user logged in from WildApricot.
 */
const User = createModel("User", {
  firstName: null,
  lastName: null,
  region: null,
  memberId: null,
  role: "user"
});

User.propTypes = {
  /** The user's first name */
  firstName: PropTypes.string.isRequired,
  /** The user's last name */
  lastName: PropTypes.string.isRequired,
  /** The AKA region that the user belongs to. A numeric string */
  region: PropTypes.string,
  /** The role of the user relating to permissions within sport.kite.org */
  role: PropTypes.oneOf(["admin", "user"]),
  /** The user's AKA member id. A numeric string */
  memberId: PropTypes.string
};

export default User;

export function getMockUser(overrides = {}) {
  return new User({
    firstName: "Frank",
    lastName: "Sinatra",
    ...overrides
  });
}
