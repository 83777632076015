import showError from "../../utils/showError";
import User from "../../models/User";

/**
 * Log in using email/password
 * @param {String} email The user's email address
 * @param {String} password The user's password
 * @return {Promise}
 */
export default async function checkLoginStatus(email, password) {
  // Make the request
  const response = await this.axiosInstance
    .post("/auth", {
      username: email,
      password
    })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // Build and return the user
  const data = response.data;
  const user = new User({
    firstName: data.firstName,
    lastName: data.lastName,
    memberId: data.memberId,
    region: data.region,
    role: data.role
  });
  return user;
}
