import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LOG_IN } from "../redux/actions";

/**
 * A container for managing data for the Log In Form
 */
export class LogInFormContainer extends React.Component {
  state = {
    emailAddress: "",
    password: "",
    isLoggingIn: false
  };

  static propTypes = {
    /** A function to provide data and methods to */
    children: PropTypes.func.isRequired,
    /** A function to call to log in the user. Provided by Redux. */
    logIn: PropTypes.func.isRequired
  };

  /**
   * Log the user in using the email and password stored in state.
   * @return {Promise} Resolves regardless of outcome
   */
  logIn = async () => {
    this.setState({ isLoggingIn: true });
    await this.props
      .logIn(this.state.emailAddress, this.state.password)
      .catch(() => {});
    this.setState({ isLoggingIn: false });
  };

  render() {
    return this.props.children({
      emailAddress: this.state.emailAddress,
      password: this.state.password,
      isLoggingIn: this.state.isLoggingIn,
      setEmail: email => this.setState({ emailAddress: email }),
      setPassword: password => this.setState({ password: password }),
      submit: this.logIn
    });
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  logIn: LOG_IN
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInFormContainer);
